import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ClientSideOnly } from '../ClientSideOnly';

export const AttentiveScript: React.FC = () => {
  return (
    <ClientSideOnly>
      <Helmet>
        <script
          async
          type="text/javascript"
          src="https://kfeel.modaoperandi.com/providers/atLabel/load"
          onError={() =>
            "var fallbackScript = document.createElement('script'); fallbackScript.src='https://cdn.attn.tv/modaoperandi/dtag.js'; document.head.appendChild(fallbackScript);"
          }
        />
      </Helmet>
    </ClientSideOnly>
  );
};
